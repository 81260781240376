
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import breadcrumbs from '@c/breadcrumbs.vue'
import sideImgList from '@c/sideImgList.vue'
import sideCommodity from '@c/sideCommodity.vue'
import sideInformation from '@c/sideInformation.vue'
import { getInformationDetail } from '@api/news'
export default {
    name: 'NewsDetail',
    components: {
        pageHeader,
        pageFooter,
        breadcrumbs,
        sideImgList,
        sideCommodity,
        sideInformation
    },
    data() {
        return {
            detail: {},
            informationid: ''
        }
    },
    methods: {
        /**
         * 是否关注了 该资讯或者商品
         * 必传: paramid（资讯的时候 是informationid,商品的时候 是commodityid），type（1 资讯 2 商品）
         */
        // async iscollection(paramid) {
        //     const res = await iscollection({
        //         paramid,
        //         type: 1
        //     })
        // },
        /**
         * 关注（取消关注）该资讯或者商品
         * 必传: paramid（资讯的时候 是informationid,商品的时候 是commodityid），type（1 资讯 2 商品）
         */
        // async collection(paramid, status = 1) {
        //     const res = await collection({
        //         paramid,
        //         type: 1,
        //         status
        //     })
        // },
        // 获取资讯详情
        async getData(informationid) {
            const res = await getInformationDetail({
                informationid
            })
            if (res && res.detail) {
                this.detail = Object.assign({}, this.detail, res.detail)
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log(to, from)
        next(vm => {
            console.log(vm.$route.query)
            vm.informationid = vm.$route.query.id
            if (vm.informationid) {
                // vm.iscollection(vm.informationid)
                // vm.collection(vm.informationid)
                vm.getData(vm.informationid)
            }
        })
    }
}
